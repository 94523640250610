const jwt = require('jsonwebtoken');

export function  DataToken(token) {
    
    const decoded = jwt.decode(token, {complete: true});
    console.log(decoded);
    return decoded.payload;
}

export function TanggalToISO(tgl) {
    let ta = tgl.split("/")
    return new Date(ta[2]+'-'+ta[1]+'-'+ta[0]).toISOString();
}

export function Tanggal(tgl) {
    var d = new Date(tgl);
    // var month = new Array();
    var month = [];
    month[0] = "Januari";
    month[1] = "Februari";
    month[2] = "Maret";
    month[3] = "April";
    month[4] = "Mei";
    month[5] = "Juni";
    month[6] = "Juli";
    month[7] = "Agustus";
    month[8] = "September";
    month[9] = "Oktober";
    month[10] = "November";
    month[11] = "Desember";
    var tanggal = d.getDate();
    var bln = month[d.getMonth()];
    var tahun = d.getFullYear();
    return tanggal+" "+bln+ " "+tahun;
}

export function Hari(tgl) {
    var d = new Date(tgl);
    // var days = new Array();
    var days = [];
    days[0] = "Minggu";
    days[1] = "Senin";
    days[2] = "Selasa";
    days[3] = "Rabu";
    days[4] = "Kamis";
    days[5] = "Jum'at";
    days[6] = "Sabtu";
    var tanggal = Tanggal(d);
    var hari = days[d.getDay()];
    return hari;
}

export function HariTanggal(tgl) {
    var d = new Date(tgl);
    // var days = new Array();
    var days = [];
    days[0] = "Minggu";
    days[1] = "Senin";
    days[2] = "Selasa";
    days[3] = "Rabu";
    days[4] = "Kamis";
    days[5] = "Jum'at";
    days[6] = "Sabtu";
    var tanggal = Tanggal(d);
    var hari = days[d.getDay()];
    return hari+", "+tanggal;
}

export function HariTanggalJam(tgl) {
    var d = new Date(tgl);
    // var days = new Array();
    var days = [];
    days[0] = "Minggu";
    days[1] = "Senin";
    days[2] = "Selasa";
    days[3] = "Rabu";
    days[4] = "Kamis";
    days[5] = "Jum'at";
    days[6] = "Sabtu";
    var tanggal = Tanggal(d);
    var hari = days[d.getDay()];
    var menit = d.getMinutes() < 10? "0"+d.getMinutes():d.getMinutes();
    var jam = d.getHours() < 10? "0"+d.getHours():d.getHours();
    var waktu = jam +":"+menit;
    return hari+", "+tanggal+" "+waktu;
}