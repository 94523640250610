import React, { Component } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown.js';

//import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

// koneksi
import {  Get } from '../../../function/Koneksi';

// Import custom components
import Breadcrumb from '../../common/breadcrumb.component';
//import BoxRowOne from './box-row-one.component';

drilldown(Highcharts);

class Default extends Component {
	constructor(props) {
		super(props);

		this.state = {
			penduduk: null,
			non_penduduk: null,
			detail_penduduk: null,
			tgl_produk_mulai: '',
			tgl_produk_selesai: '',
			produk_tahun: null,
			detail_produk_desa: null,
			tgl_transaksi_mulai: '',
			tgl_transaksi_selesai: '',
			transaksi_bulan: null,
			detail_transaksi: null,
			toko:null,
			detail_toko:null,
			detail_penjual:null,
			total_penjual:null
		};
	}

	componentDidMount() {
		//GET TOKO

		Get('chart/toko', null, (data) => {
			console.log('data toko : ',data)

			if (data.result) {
				
				//console.log('jumlah toko : ',data.result.toko)
				//console.log('jenis toko : ',data.result.child.jenis_toko)

				var getToko = data.result.child;
				var tmp = [];

				//console.log('data toko : ',getToko)

				getToko.forEach((element) => {
					tmp.push([ element.jenis_toko, element.jumlah ]);
				});

				//console.log('data tmp : ',tmp)

				this.setState({
					//total_toko: data.result.toko,
					detail_toko: tmp
				});
			}
		});

		Get('chart/penjual', null, (data) => {

			if (data.result) {
				
				var getPenjual = data.result.child;
				var tmp = [];
				
				getPenjual.forEach((element) => {
					//console.log('elemet :', element.data_pengguna.username);
					tmp.push({
						name:element.data_pengguna.username,
						y:element.jumlah,
					});
				
				});

				

				this.setState({
					
					total_penjual: data.result.total_penjual,
					detail_penjual: tmp
				});
			}
		});



		// CHART PENGGUNA
		// Get('chart/chart-pengguna', null, (data) => {
		// 	if (data.result) {
		// 		console.log(data.result);
		// 		console.log('Jumlah USER');
		// 		console.log(data.result.akumulasi_user_terdaftar);
		// 		console.log('Jumlah USER penduduk');
		// 		console.log(data.result.child.jumlah_penduduk_terdaftar.total);
		// 		console.log('Jumlah USER non penduduk');
		// 		console.log(data.result.child.jumlah_penduduk_non_terdaftar.total);

		// 		var tesbbos = data.result.child.jumlah_penduduk_terdaftar.child;
		// 		var tmp = [];
		// 		console.log(tesbbos);
		// 		tesbbos.forEach((element) => {
		// 			tmp.push([ element.data_keldes.nama, element.total ]);
		// 		});
		// 		console.log(tmp);
		// 		console.log('sini');

		// 		this.setState({
		// 			penduduk: data.result.child.jumlah_penduduk_terdaftar.total,
		// 			non_penduduk: data.result.child.jumlah_penduduk_non_terdaftar.total,
		// 			// detail_penduduk: data.result.child.jumlah_penduduk_terdaftar.child,
		// 			detail_penduduk: tmp
		// 		});
		// 	}
		// });

		//CHART PRODUK

		Get('chart/produk', null, (data) => {
			// console.log('data : ',data);
			if (data.result) {
				// console.log('Chart Produk');
				// console.log(data.result);

				var tesbbos = data.result;
				var tmp_produk = [];
				var detail = [];

				// console.log(tesbbos);

				tesbbos.forEach((element) => {
					var bulan = element.bulan;
					var get_bulan = null;
					if (bulan === 1) {
						get_bulan = 'Januari';
					} else if (bulan === 2) {
						get_bulan = 'Februari';
					} else if (bulan === 3) {
						get_bulan = 'Maret';
					} else if (bulan === 4) {
						get_bulan = 'April';
					} else if (bulan === 5) {
						get_bulan = 'Mei';
					} else if (bulan === 6) {
						get_bulan = 'Juni';
					} else if (bulan === 7) {
						get_bulan = 'Juli';
					} else if (bulan === 8) {
						get_bulan = 'Agustus';
					} else if (bulan === 9) {
						get_bulan = 'September';
					} else if (bulan === 10) {
						get_bulan = 'Oktober';
					} else if (bulan === 11) {
						get_bulan = 'November';
					} else if (bulan === 12) {
						get_bulan = 'Desember';
					}
					// tmp_produk.push("{name: 'Tahun "+element.tahun +"',y: "+ element.total_produk+",drilldown: '"+element.tahun+"'}")
					tmp_produk.push({
						name: 'Bulan ' + get_bulan,
						y: element.total_produk,
						// drilldown: '' + element.bulan + ''
					});

					// console.log('TMP PRODUK');
					// console.log(tmp_produk);
					//var desa = element.child;
					// console.log('CHILD');
					// console.log(desa);

					// var isi_detail = [];
					// desa.forEach((el) => {
					// 	isi_detail.push([ el.nama, el.jumlah_produk ]);
					// });

					// detail.push({
					// 	name: 'Produk Desa',
					// 	id: element.bulan + '',
					// 	data: isi_detail
					// });
				});

				// this.setState({
				// 	detail_produk_desa: detail
				// });
				// console.log(this.state.detail_produk_desa);

				// console.log('Coba Join');
				// console.log(tmp_produk);

				// console.log('END Coba Join');
				// console.log('CONTOH');
				// console.log(
				// 	" {name: 'Tahun 2017',y: 60,drilldown: '2017'}, {name: 'Tahun 2018',y: 120,drilldown: '2018'}, {name: 'Tahun 2019',y: 200,drilldown: '2019'}, {name: 'Tahun 2020',y: 400,drilldown: '2020'}"
				// );

				this.setState({
					produk_tahun: tmp_produk,
					detail_produk_desa: detail
				});
			}
		});

		// CHART TRANSAKSI

		Get('chart/transaksi', null, (data) => {
			if (data.result) {
				// console.log('Chart Transaksi');
				// console.log(data.result);

				var tesbbos = data.result;
				var tmp_produk = [];
				var drill1 = [];
				// var drill2 = [];
				// var drilldown = [];

				// console.log(tesbbos);

				tesbbos.forEach((element) => {
					var bulan = element.bulan;
					var get_bulan = null;
					if (bulan === 1) {
						get_bulan = 'Januari';
					} else if (bulan === 2) {
						get_bulan = 'Februari';
					} else if (bulan === 3) {
						get_bulan = 'Maret';
					} else if (bulan === 4) {
						get_bulan = 'April';
					} else if (bulan === 5) {
						get_bulan = 'Mei';
					} else if (bulan === 6) {
						get_bulan = 'Juni';
					} else if (bulan === 7) {
						get_bulan = 'Juli';
					} else if (bulan === 8) {
						get_bulan = 'Agustus';
					} else if (bulan === 9) {
						get_bulan = 'September';
					} else if (bulan === 10) {
						get_bulan = 'Oktober';
					} else if (bulan === 11) {
						get_bulan = 'November';
					} else if (bulan === 12) {
						get_bulan = 'Desember';
					}
					// tmp_produk.push("{name: 'Tahun "+element.tahun +"',y: "+ element.total_produk+",drilldown: '"+element.tahun+"'}")
					tmp_produk.push({
						name: 'Bulan ' + get_bulan,
						y: element.total_transaksi,
						tahun: element.tahun,
						drilldown: '' + element.bulan + ''
					});

					// console.log('TMP TRANSAKSI');
					// console.log(tmp_produk);
					var transaksi = element.child;
					// console.log('CHILD TRANSAKSI');
					// console.log(transaksi);

					var isi_drill1 = [];

					transaksi.forEach((el) => {
						isi_drill1.push({
							name: el.nama,
							y: el.jumlah_transaksi,
							drilldown: el.id + ''
						});

						var drill_detail_transaksi = el.child;
						var isi_drill2 = [];

						drill_detail_transaksi.forEach((el_detail) => {
							isi_drill2.push([ el_detail.status, el_detail.total ]);
						});

						// console.log('DATA ISI DRILL 2');
						// console.log(isi_drill2);

						drill1.push({
							name: 'Status Transaksi',
							id: el.id + '',
							data: isi_drill2
						});
					});

					// console.log('DATA ISI DRILL 1');
					// console.log(isi_drill1);

				
					drill1.push({
						name: 'Transaksi Desa',
						id: element.bulan + '',
						data: isi_drill1
					});
				});

				// drilldown.push({ drill1, drill2 });

				// console.log('TRANSAKSI DESA');
				// console.log(drill1);

				// console.log('STATUS TRANSAKSI DESA');
				// console.log(drill2);

				// console.log('DRILLDOWN');
				// console.log(drilldown);

				this.setState({
					transaksi_bulan: tmp_produk,
					detail_transaksi: drill1
				});
				// console.log(this.state.detail_transaksi);
			}
		});

		// console.log(localStorage.getItem('access_token'));
	}

	onChangeDateMulai = (e) => {
		if (e) {
			this.setState({ tgl_produk_mulai: e.target.value });
		}
	};

	onChangeDateSelesai = (e) => {
		if (e) {
			this.setState({ tgl_produk_selesai: e.target.value });
		}
	};

	onSubmit = (e) => {
		e.preventDefault();
		var tgl_produk_mulai = this.state.tgl_produk_mulai;
		var tgl_produk_selesai = this.state.tgl_produk_selesai;
		var url_tanggal = '';

		//this.state.produk_tahun = null;
		// console.log(tgl_produk_mulai);
		// console.log(tgl_produk_selesai);
		if (tgl_produk_mulai === '' && tgl_produk_selesai === '') {
			url_tanggal = 'chart/produk';
		} else {
			url_tanggal = 'chart/produk?from=' + tgl_produk_mulai + '&to=' + tgl_produk_selesai;
		}
		Get(url_tanggal, null, (data) => {
			if (data.result) {
				// console.log('Data Filter');
				// console.log(data.result);
				// this.setState({ data: data.data.result.data, pagination: data.data.result });

				var tesbbos = data.result;
				var tmp_produk = [];
				// console.log(tesbbos);

				tesbbos.forEach((element) => {
					var bulan = element.bulan;
					var get_bulan = null;
					if (bulan === 1) {
						get_bulan = 'Januari';
					} else if (bulan === 2) {
						get_bulan = 'Februari';
					} else if (bulan === 3) {
						get_bulan = 'Maret';
					} else if (bulan === 4) {
						get_bulan = 'April';
					} else if (bulan === 5) {
						get_bulan = 'Mei';
					} else if (bulan === 6) {
						get_bulan = 'Juni';
					} else if (bulan === 7) {
						get_bulan = 'Juli';
					} else if (bulan === 8) {
						get_bulan = 'Agustus';
					} else if (bulan === 9) {
						get_bulan = 'September';
					} else if (bulan === 10) {
						get_bulan = 'Oktober';
					} else if (bulan === 11) {
						get_bulan = 'November';
					} else if (bulan === 12) {
						get_bulan = 'Desember';
					}
					// tmp_produk.push("{name: 'Tahun "+element.tahun +"',y: "+ element.total_produk+",drilldown: '"+element.tahun+"'}")
					tmp_produk.push({
						name: 'Bulan ' + get_bulan,
						y: element.total_produk,
						// drilldown: '' + element.bulan + ''
					});
				});

				// var produkStr = JSON.parse(tmp_produk);

				// var res_produk = tmp_produk.replace(/"/g, "");
				// var res_produk2 = res_produk.replace("[", '"');
				// var res_produk3 = res_produk2.replace("]", '"');

				// console.log('Coba Join');
				// console.log(tmp_produk);
				// console.log(res_produk);
				// console.log('END Coba Join');
				// console.log('CONTOH');
				// console.log(
				// 	" {name: 'Tahun 2017',y: 60,drilldown: '2017'}, {name: 'Tahun 2018',y: 120,drilldown: '2018'}, {name: 'Tahun 2019',y: 200,drilldown: '2019'}, {name: 'Tahun 2020',y: 400,drilldown: '2020'}"
				// );

				this.setState({
					produk_tahun: tmp_produk
				});
			}
		});
	};

	onChangeDateMulai2 = (e) => {
		if (e) {
			this.setState({ tgl_transaksi_mulai: e.target.value });
		}
	};

	onChangeDateSelesai2 = (e) => {
		if (e) {
			this.setState({ tgl_transaksi_selesai: e.target.value });
		}
	};

	onSubmit2 = (e) => {
		e.preventDefault();
		var tgl_transaksi_mulai = this.state.tgl_transaksi_mulai;
		var tgl_transaksi_selesai = this.state.tgl_transaksi_selesai;
		var url_transaksi = '';

		this.state.transaksi_bulan = null;
		this.state.detail_transaksi = null;
		// console.log(tgl_transaksi_mulai);
		// console.log(tgl_transaksi_selesai);
		if (tgl_transaksi_mulai == '' && tgl_transaksi_selesai == '') {
			url_transaksi = 'chart/transaksi';
		} else {
			url_transaksi = 'chart/transaksi?from=' + tgl_transaksi_mulai + '&to=' + tgl_transaksi_selesai;
		}
		Get(url_transaksi, null, (data) => {
			if (data.result) {
				// console.log('Chart Transaksi');
				// console.log(data.result);

				var tesbbos = data.result;
				var tmp_produk = [];
				var drill1 = [];
				// var drill2 = [];
				// var drilldown = [];

				// console.log(tesbbos);

				tesbbos.forEach((element) => {
					var bulan = element.bulan;
					var get_bulan = null;
					if (bulan === 1) {
						get_bulan = 'Januari';
					} else if (bulan === 2) {
						get_bulan = 'Februari';
					} else if (bulan === 3) {
						get_bulan = 'Maret';
					} else if (bulan === 4) {
						get_bulan = 'April';
					} else if (bulan === 5) {
						get_bulan = 'Mei';
					} else if (bulan === 6) {
						get_bulan = 'Juni';
					} else if (bulan === 7) {
						get_bulan = 'Juli';
					} else if (bulan === 8) {
						get_bulan = 'Agustus';
					} else if (bulan === 9) {
						get_bulan = 'September';
					} else if (bulan === 10) {
						get_bulan = 'Oktober';
					} else if (bulan === 11) {
						get_bulan = 'November';
					} else if (bulan === 12) {
						get_bulan = 'Desember';
					}
					// tmp_produk.push("{name: 'Tahun "+element.tahun +"',y: "+ element.total_produk+",drilldown: '"+element.tahun+"'}")
					tmp_produk.push({
						name: 'Bulan ' + get_bulan,
						y: element.total_transaksi,
						tahun: element.tahun,
						drilldown: '' + element.bulan + ''
					});

					// console.log('TMP TRANSAKSI');
					// console.log(tmp_produk);
					var transaksi = element.child;
					// console.log('CHILD TRANSAKSI');
					// console.log(transaksi);

					var isi_drill1 = [];

					transaksi.forEach((el) => {
						isi_drill1.push({
							name: el.nama,
							y: el.jumlah_transaksi,
							drilldown: el.id + ''
						});

						var drill_detail_transaksi = el.child;
						var isi_drill2 = [];

						drill_detail_transaksi.forEach((el_detail) => {
							isi_drill2.push([ el_detail.status, el_detail.total ]);
						});

						// console.log('DATA ISI DRILL 2');
						// console.log(isi_drill2);

						drill1.push({
							name: 'Status Transaksi',
							id: el.id + '',
							data: isi_drill2
						});
					});

					// console.log('DATA ISI DRILL 1');
					// console.log(isi_drill1);

					// var drill3 = [];
					// drill_detail_transaksi.forEach((el) => {
					// 	drill3.push({
					// 		name: el.nama,
					// 		id: el.id,
					// 		data: [ [ 'v40.0', 5 ] ]
					// 	});
					// });

					drill1.push({
						name: 'Transaksi Desa',
						id: element.bulan + '',
						data: isi_drill1
					});
				});

				// drilldown.push({ drill1, drill2 });

				// console.log('TRANSAKSI DESA');
				// console.log(drill1);

				// console.log('STATUS TRANSAKSI DESA');
				// console.log(drill2);

				// console.log('DRILLDOWN');
				// console.log(drilldown);

				this.setState({
					transaksi_bulan: tmp_produk,
					detail_transaksi: drill1
				});
				// console.log(this.state.detail_transaksi);
			}
		});
	};

	render() {
		// console.log('COBA TAMPILKAN');
		// console.log(this.state.penduduk);
		// console.log(this.state.non_penduduk);
		// console.log(this.state.detail_penduduk);
		// console.log('COBA AJA BOSKU');
		// console.log(this.state.detail_transaksi);

		// console.log('Produk TAMPILKAN');
		// console.log(this.state.produk_tahun);

		// console.log('Tanggal');
		// console.log(this.state.tgl_produk_mulai);
		// console.log(this.state.tgl_produk_selesai);

		// =====================================================================
		// const pengguna = {
		// 	chart: {
		// 		type: 'pie'
		// 	},

		// 	title: {
		// 		text: 'Pengguna Marketplace Terdaftar'
		// 	},
		// 	subtitle: {
		// 		text: 'Klik pada grafik untuk melihat detail data'
		// 	},
		// 	xAxis: {
		// 		type: 'category'
		// 	},
		// 	yAxis: {
		// 		title: {
		// 			text: 'Total percent market share'
		// 		}
		// 	},
		// 	legend: {
		// 		enabled: false
		// 	},
		// 	plotOptions: {
		// 		series: {
		// 			pointPadding: 0.2,
		// 			borderWidth: 0,
		// 			dataLabels: {
		// 				enabled: true,
		// 				format: '{point.y}'
		// 			}
		// 		}
		// 	},

		// 	tooltip: {
		// 		shared: true,
		// 		headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
		// 		pointFormat:
		// 			'<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total <b>{point.total}</b><br/>'
		// 	},

		// 	series: [
		// 		{
		// 			name: 'Pengguna',
		// 			colorByPoint: true,
		// 			data: [
		// 				{
		// 					name: 'Jumlah Penduduk Terdaftar',
		// 					y: this.state.penduduk,
		// 					drilldown: 'Detail'
		// 				},

		// 				{
		// 					name: 'Jumlah Non Penduduk Terdaftar',
		// 					y: this.state.non_penduduk
		// 				}
		// 			]
		// 		}
		// 	],

		// 	drilldown: {
		// 		series: [
		// 			{
		// 				name: 'Pengguna Penduduk',
		// 				id: 'Detail',
		// 				data: this.state.detail_penduduk
		// 			}
		// 		]
		// 	}
		// };
		// =====================================================================
		const produk = {
			chart: {
				type: 'column'
			},

			title: {
				text: 'Produk'
			},
			// subtitle: {
			// 	text: 'Klik pada grafik untuk melihat detail data'
			// },
			xAxis: {
				type: 'category'
			},
			yAxis: {
				title: {
					text: 'Total percent market share'
				}
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					pointPadding: 0.2,
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.y}'
					}
				}
			},

			tooltip: {
				shared: true,
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
			},

			series: [
				{
					name: 'Produk',
					colorByPoint: true,
					data: this.state.produk_tahun
				}
			],

			// drilldown: {
			// 	series: this.state.detail_produk_desa
			// 	// [
			// 	// 	{
			// 	// 		name: 'Produk Desa',
			// 	// 		id: '10',
			// 	// 		data: this.state.detail_produk_desa
			// 	// 	}
			// 	// ]
			// }
		};

		// =====================================================================
		const transaksi = {
			chart: {
				type: 'column'
			},

			title: {
				text: 'Transaksi'
			},
			subtitle: {
				text: 'Klik pada grafik untuk melihat detail data'
			},
			xAxis: {
				type: 'category'
			},
			yAxis: {
				title: {
					text: 'Total percent market share'
				}
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					pointPadding: 0.2,
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.y}'
					}
				}
			},

			tooltip: {
				shared: true,
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
			},

			series: [
				{
					name: 'Transaksi',
					colorByPoint: true,
					data: this.state.transaksi_bulan
				}
			],

			drilldown: {
				series: this.state.detail_transaksi
				// [
				// 	{
				// 		name: 'Produk Desa',
				// 		id: '10',
				// 		data: this.state.detail_produk_desa
				// 	}
				// ]
			}
		};

		const toko = {
			chart: {
				type: 'pie'
			},

			title: {
				text: 'Data Toko / UMKM'
			},
			// subtitle: {
			// 	text: 'Klik pada grafik untuk melihat detail data'
			// },
			xAxis: {
				type: 'category'
			},
			yAxis: {
				title: {
					text: 'Total percent market share'
				}
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					pointPadding: 0.2,
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.y}'
					}
				}
			},

			tooltip: {
				shared: true,
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat:
					'<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total <b>{point.total}</b><br/>'
			},

			series: [
				{
					name: 'jumlah Toko',
					colorByPoint: true,
					data: this.state.detail_toko
					// data: [
					// 	// {
					// 	// 	name: 'Jumlah Toko / UMKM',
					// 	// 	y: this.state.total_toko,
					// 	// 	drilldown: 'Detail'
					// 	// },

					// 	{
					// 		name: 'Toko Pribadi',
					// 		y: 10,
					// 		//drilldown: 'Detail'
					// 	},

					// 	{
					// 		name: 'Toko UMKM',
					// 		y: 7,
					// 		//drilldown: 'Detail'
					// 	},

					// 	// {
					// 	// 	name: 'Jumlah Non Penduduk Terdaftar',
					// 	// 	y: this.state.non_penduduk
					// 	// }
					// ]
				}
			],

			// drilldown: {
			// 	series: [
			// 		{
			// 			name: 'Toko',
			// 			id: 'Detail',
			// 			data: this.state.detail_toko
			// 		}
			// 	]
			// }
		};

		const penjual = {
			chart: {
				type: 'column'
			},

			title: {
				text: 'Pemilik Toko / UMKM'
			},
			subtitle: {
				text: 'Klik pada grafik untuk melihat detail data'
			},
			xAxis: {
				type: 'category'
			},
			yAxis: {
				title: {
					text: 'Total percent market share'
				}
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					pointPadding: 0.2,
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.y}'
					}
				}
			},

			tooltip: {
				shared: true,
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat:
					'<span style="color:{point.color}">{point.name}</span> : <b>{point.y}</b><br/>'
			},

			series: [
				{
					name: 'Pemilik Toko / UMKM',
					colorByPoint: true,
					//data: this.state.total_penjual
					data: [
						{
							name: 'Jumlah Pemilik Toko',
							y: this.state.total_penjual,
							drilldown: 'Detail'
						},

					]
				}
			],

			drilldown: {
				

				series: [
					{
						name: 'Jumlah Toko',
						colorByPoint: true,
						id: 'Detail',
					
						data: this.state.detail_penjual
					}
				]
			}
		};


		// =====================================================================
		// console.log(this.state.produk_tahun);
		// console.log(this.state.detail_produk_desa);
		return (
			<div>
				{/*Container-fluid starts*/}
				<Breadcrumb title="Dashboard" parent="Admin" />
				{/*Container-fluid Ends*/}

				{/*Container-fluid starts*/}
				<div className="container-fluid">
					{/* <BoxRowOne /> */}
					<div className="row">
						{/* ===========================================						 */}
						{/* <div className="col-xl-12 col-lg-12">
							<div className="card">
								<div className="card-header">
									<h5>Chart Pengguna Marketplace</h5>
									<span>Contrary to popular belief, Lorem Ipsum is not simply random text.</span>
								</div>
								<div className="card-body">
									<div className="dashboard-chart-container sales-overview-chart">
										<HighchartsReact highcharts={Highcharts} options={pengguna} />
									</div>
								</div>
							</div>
						</div> */}
						{/* =============================================== */}

						
						{/* =============================================== */}

						<div className="col-xl-12 col-lg-12">
							<div className="card height-equal equal-height-lg">
								<div className="card-header">
									<h5>TOKO CHART</h5>
								</div>
								<div className="card-body">
								
									<div className="user-status height-scroll custom-scrollbar">
										<HighchartsReact highcharts={Highcharts} options={toko} />
									</div>
								</div>
							</div>
						</div>

						{/* ====================================================== */}

						{/* =============================================== */}

						<div className="col-xl-12 col-lg-12">
							<div className="card height-equal equal-height-lg">
								<div className="card-header">
									<h5>PEMILIK TOKO  CHART</h5>
								</div>
								<div className="card-body">
								
									<div className="user-status height-scroll custom-scrollbar">
										<HighchartsReact highcharts={Highcharts} options={penjual} />
									</div>
								</div>
							</div>
						</div>

						{/* ====================================================== */}


						<div className="col-xl-12 col-lg-12">
							<div className="card height-equal equal-height-lg">
								<div className="card-header">
									<h5>PRODUCT CHART</h5>
								</div>
								<div className="card-body">
									<form>
										<div className="row">
											<div className="col-6">
												<input
													type="date"
													id="tgl_produk_mulai"
													className="form-control"
													value={this.state.tgl_produk_mulai}
													onChange={this.onChangeDateMulai}
													style={{ marginBottom: '10px' }}
												/>
											</div>
											<div className="col-6">
												<input
													type="date"
													id="tgl_produk_selesai"
													className="form-control"
													value={this.state.tgl_produk_selesai}
													onChange={this.onChangeDateSelesai}
													style={{ marginBottom: '10px' }}
												/>
											</div>
											<div className="col-12" align="right">
												<button
													onClick={(e) => this.onSubmit(e)}
													color="primary"
													className="btn btn-primary"
												>
													Cari Data
												</button>
											</div>
										</div>
									</form>
									<div className="user-status height-scroll custom-scrollbar">
										<HighchartsReact highcharts={Highcharts} options={produk} />
									</div>
								</div>
							</div>
						</div>
						
						{/* =============================================================== */}

						<div className="col-xl-12 col-lg-12">
							<div className="card height-equal equal-height-lg">
								<div className="card-header">
									<h5>TRANSAKSI CHART</h5>
								</div>
								<div className="card-body">
									<form>
										<div className="row">
											<div className="col-6">
												<input
													type="date"
													id="tgl_transaksi_mulai"
													className="form-control"
													value={this.state.tgl_transaksi_mulai}
													onChange={this.onChangeDateMulai2}
													style={{ marginBottom: '10px' }}
												/>
											</div>
											<div className="col-6">
												<input
													type="date"
													id="tgl_transaksi_selesai"
													className="form-control"
													value={this.state.tgl_transaksi_selesai}
													onChange={this.onChangeDateSelesai2}
													style={{ marginBottom: '10px' }}
												/>
											</div>
											<div className="col-12" align="right">
												<button
													onClick={(e) => this.onSubmit2(e)}
													color="primary"
													className="btn btn-primary"
												>
													Cari Data
												</button>
											</div>
										</div>
									</form>
									<div className="user-status height-scroll custom-scrollbar">
										<HighchartsReact highcharts={Highcharts} options={transaksi} />
									</div>
								</div>
							</div>
						</div>
						
						
					</div>
				</div>
				
			</div>
		);
	}
}

export default Default;
