import React, { Component } from 'react';
import {Link} from 'react-router-dom'
// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


const frmDef = {
	uuid: '',
	jenis_pembayaran: '',
}

class Transaksi extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Transaksi",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			name:'',

			kode_transaksi:'',

			//Tab Pane
			active_tab_icon: '2',
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
		  this.setState({
			active_tab_icon: tab
		  });
		}
	  }

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('transaksi/sedang-verifikasi', null, (lihat) => {
			if (lihat.result) {
				this.setState({ data: lihat.result, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		if (e) {
			let addTransaksi = {
				q: e.target.value,
			};
			Post('transaksi', null, addTransaksi, (data) => {
				if (data.data.result.data) {
					this.setState({ data: data.data.result.data, pagination: data.data.result });
				}
			});
		} 
	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let addToko = {
			uuid: document.getElementById("uuid").value,
			id_pengguna: this.state.id_pengguna.value,
			id_kategori_usaha: this.state.id_kategori_usaha.value,
			id_desa: (this.state.id_desa.value).toString(),
			nama_toko: document.getElementById("nama_toko").value,
			telepon: document.getElementById("telepon").value,
			alamat: document.getElementById("alamat").value,
			lat: document.getElementById("lat").value,
			lang: document.getElementById("lang").value,
			jam_buka: document.getElementById("jam_buka").value + ':00',
			jam_tutup: document.getElementById("jam_tutup").value + ':00',
			is_active: this.state.id_status_toko.value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addToko.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addToko.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('toko/' + metode, addToko.uuid, addToko, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Toko', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Toko', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}


	goDetail = (id) => {
		this.props.history.push(`/transaksi/${id}`);

		Get('transaksi', id, (data) => {
			if (data.result) {
				this.state.data_toko.id_toko = id;
				this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

				this.state.data_toko.nama_toko = data.result.nama_toko;
				this.state.data_toko.kode_transaksi = data.result.kode_transaksi;
				this.state.data_toko.telepon_toko = data.result.telepon;
				this.state.data_toko.alamat_toko = data.result.alamat;
				this.state.data_toko.lat_toko = data.result.lat;
				this.state.data_toko.lang_toko = data.result.lang;
				this.state.data_toko.jam_buka_toko = data.result.jam_buka;
				this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
				this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Data Toko";
		this.forceUpdate();

		Get('toko/find', id, (data) => {
			if (data.result) {
				this.state.data_toko.id_toko = id;
				this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

				this.state.data_toko.nama_toko = data.result.nama_toko;
				this.state.data_toko.telepon_toko = data.result.telepon;
				this.state.data_toko.alamat_toko = data.result.alamat;
				this.state.data_toko.lat_toko = data.result.lat;
				this.state.data_toko.lang_toko = data.result.lang;
				this.state.data_toko.jam_buka_toko = data.result.jam_buka;
				this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
				this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('toko/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Toko', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Toko', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_toko.id_toko = '';
			this.state.id_kategori_usaha = '';
			this.state.id_desa = '';
			this.state.id_pengguna = '';
			this.state.data_toko.nama_toko = '';
			this.state.data_toko.telepon_toko = '';
			this.state.data_toko.alamat_toko = '';
			this.state.data_toko.lat_toko = '';
			this.state.data_toko.lang_toko = '';
			this.state.data_toko.jam_buka_toko = '';
			this.state.data_toko.jam_tutup_toko = '';
			this.state.id_status_toko = '';
			this.state.status.form = true;
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
			<Link to={{ pathname: "/detail-transaksi/", state: row }}>
				<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button> 
			</Link>
			<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
			</>
		)
	}

	//function yg pertama kali di eksekusi
	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: { value: e.value, label: e.label } });
					break;

				case 'id_desa':
					this.setState({ id_desa: { value: e.value, label: e.label } });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: { value: e.value, label: e.label } });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: '' });
					break;

				case 'id_desa':
					this.setState({ id_desa: '' });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: '' });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: '' });
					break;
			}
		}
	}
	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
			}
			Post(table, null, find_data, (data) => {
				if (data.data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;
					}
				}
			});
		} else {
			Get(table, null, data => {
				if (data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;

					}
				}
			});
		}
	}


	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

   onSubmit = (e) => {
	e.preventDefault();
	   
	   let addTransaksi = {
			q: this.state.name,
		};

		console.log("Pencariannya")
		console.log(this.state.name)
		
	   Post('transaksi', null, addTransaksi, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
         /* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
        var data = this.state.data;
        var data2 = this.state.data2;
		var data3 = this.state.data3;
		const frmDef = {
			uuid: '',
			kode_transaksi: '',
		}
        const { SearchBar } = Search;
		
		const columns = [
			
			{
				dataField: 'kode_transaksi',
				text: 'kode Transaksi',
				sort: true
			},
			
			{
				dataField: 'waktu_transaksi',
				text: 'Waktu Transaksi',
				sort: true
			},
			{
				dataField: 'pembeli',
				text: 'pembeli',
				sort: true
			},
			
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
        
		];



		// const defaultSorted = [{
		// 	dataField: 'kode_transaksi',
		// 	order: 'asc'
		// }];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Transaksi" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

                
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>


									<div>
										<Nav tabs className="tabs-color">
										
										<NavItem>
											<NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
											onClick={() => { this.toggle_icon('2'); }} >
											<i className="icofont icofont-pay"></i>Sedang Di verifikasi
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
											onClick={() => { this.toggle_icon('3'); }} >
											<i className="icofont icofont-truck-alt"></i>Sudah Bayar
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink className={classnames({ active: this.state.active_tab_icon === '4' })}
											onClick={() => { this.toggle_icon('4'); }} >
											<i className="icofont icofont-listine-dots"></i>Belum Bayar
											</NavLink>
										</NavItem>
										</Nav>
{/* ===============================================================										 */}
										<TabContent activeTab={this.state.active_tab_icon}>
										
											<TabPane tabId="2">
												<div style={{ marginTop: 15 }}>
													<div className="row">
														<div className="col-sm-6">
															{/* <form>
																<label>
																	<input
																		name='name'
																		value={this.state.name}
																		onChange={e => this.handleChange(e)}
																		style={{ marginBottom: "10px" }}
																		className="form-control"
																		placeholder="Ketikkan sesuatu..."
																		/>
																</label>

																<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>
															</form> */}
														</div>
													</div>

													<ToolkitProvider
														keyField="uuid"
														data={data}
														columns={columns}
														search >
														{
															props => (
																<div>
																	<BootstrapTable
																		{...props.baseProps}
																	/>
																</div>
															)
														}
													</ToolkitProvider>

													<div className="pull-right text-white">
														{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
													</div>

												</div>
											</TabPane>

										</TabContent>

									</div>

                                    
								</div>
							</div>
						</div>
					</div>
				</div>

				
				<br />
				
			</div>
		)
	}
}


export default Transaksi;
