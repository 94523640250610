import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col
} from 'reactstrap';
import classnames from 'classnames';

// koneksi
import { Post, Get, Delete, GetMobile, cekLogin, Patch } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class VerifikasiPengembalianDana extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],

			//state modal
			status: {
				formDetail: false,
				btnForm: false,
				btnAksi: false
			},

			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',

			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',

			//Tab Pane
			active_tab_icon: '1',

			name:''
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({ data: [] });

			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: null });
					break;

				case '2':
					this.fetch({ page: null, where: [], tab: 'berhasil' });
					break;

				default:
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};
	// =================================================

	fetch = (params = {}) => {
		let link = '';
		let where = [];
		let id_category = null;
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		if (params.tab == null) {
			Get('transaksi/pengembalian-dana' + link, null, (lihat) => {
				if (lihat.result) {
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		} else if (params.tab == 'berhasil') {
			Get('transaksi/pengembalian-dana/berhasil' + link, null, (lihat) => {
				if (lihat.result) {
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		}
	};

	// Pagination
	pagination = (data, type = null) => {
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				if (type == null) {
					hal.push(
						<a
							key={i}
							onClick={() => this.fetch({ page: i })}
							className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
						>
							{i}
						</a>
					);
				} else {
					hal.push(
						<a
							key={i}
							onClick={() => this.fetch({ page: i, category: type })}
							className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
						>
							{i}
						</a>
					);
				}
			}
			if (p > 0) {
				if (type == null) {
					sebelum = (
						<a
							onClick={() => this.fetch({ page: p })}
							className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						>
							{'<'}
						</a>
					);
				} else {
					sebelum = (
						<a
							onClick={() => this.fetch({ page: p, category: type })}
							className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						>
							{'<'}
						</a>
					);
				}
			}
			if (n > 0) {
				if (type == null) {
					setelah = (
						<a
							onClick={() => this.fetch({ page: n })}
							className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						>
							{'>'}
						</a>
					);
				} else {
					setelah = (
						<a
							onClick={() => this.fetch({ page: n, category: type })}
							className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						>
							{'>'}
						</a>
					);
				}
			}

			if (type == null) {
				awal = (
					<a
						onClick={() => this.fetch({ page: 1 })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<<'}
					</a>
				);
				akhir = (
					<a
						onClick={() => this.fetch({ page: data.last_page })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>>'}
					</a>
				);
			} else {
				awal = (
					<a
						onClick={() => this.fetch({ page: 1, category: type })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<<'}
					</a>
				);
				akhir = (
					<a
						onClick={() => this.fetch({ page: data.last_page, category: type })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>>'}
					</a>
				);
			}
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// Cari Data Table
	cariData = (e) => {
		if (e) {
			let addToko = {
				q: e.target.value
			};
			// Post('toko', null, addToko, (data) => {
			//   if (data.data.result) {
			//     this.setState({ data: data.data.result.data, pagination: data.data.result });
			//   }
			// });
		} else {
			// Get('toko', null, (lihat) => {
			//   if (lihat.result) {
			//     this.setState({ data: lihat.result.data, pagination: lihat.result });
			//     this.pagination(lihat.result);
			//   }
			// });
		}
	};

	simpan = (e) => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let addStatus = {
			kode_refrensi_bank: document.getElementById('kode_refrensi_bank').value,
			status: 'Diterima',
			id_bank: this.state.id_bank
		};

		let newArray = this.state.pencairanDanaId;

		for (var i = 0; i < newArray.length; i++) {
			var idnya = newArray[i];

			let psn = 'Dibayar';
			let resstat = 200;
			let metode = 'create';

			Post('pencairan/verifikasi', idnya, addStatus, (res) => {
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						pageNumber: 0,
						pencairanDanaId: [],
						show: true,
						basicType: 'success',
						basicTitle: 'Data Pencairan Dana',
						pesanAlert: 'Berhasil'
					});
				} else {
					this.setState({
						pageNumber: 0,
						pencairanDanaId: [],
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pencairan Dana',
						pesanAlert: 'Gagal'
					});
				}
				this.componentDidMount();
			});
		}
	};

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('toko/delete', id, (res) => {
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Produk',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Produk',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.status.form = true;
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.forceUpdate();
		}
	};

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	};

	componentDidMount() {
		this.fetch({ page: null, where: [], tab: null });
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onSubmit = (e) => {
		e.preventDefault();
		let link = "?page=1";
		let search = {
			q: this.state.name
		};

		// Get('transaksi/pengembalian-dana/berhasil' + link, null, search,(lihat) => {
		// 	if (lihat.result) {
		// 		this.setState({ data: lihat.result.data, pagination: lihat.result });
		// 		this.pagination(lihat.result);
		// 	}
		// });

		
	};

	konfirmasiPengembalianDana(id_transaksi) {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Ya, kembalikan dana"
					confirmBtnBsStyle="warning"
					cancelBtnText="Tidak"
					cancelBtnBsStyle="success"
					type="warning"
					title="Apakah anda yakin akan mengembalikan dana ke pembeli ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.kembalikanDana(id_transaksi)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	kembalikanDana(id_transaksi) {
		this.hideAlert();

		let addData = {
			id_transaksi_toko: id_transaksi
		};

		let psn = 'pengembalian dana';
		let resstat = 200;

		Post('transaksi/pengembalian-dana/acc', null, addData, (res) => {
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Pengembalian Dana',
					pesanAlert: 'Berhasil melakukan ' + psn
				});
				this.componentDidMount();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Pengembalian Dana',
					pesanAlert: 'Gagal melakukan ' + psn
				});
			}
		});
	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;

		let data = this.state.data;

		const { SearchBar } = Search;

		const defaultSorted = [
			{
				dataField: 'jenis_pembayaran',
				order: 'asc'
			}
		];

		const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>
						{page}
					</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing {from} to {to} of {total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		const columns = [
			{
				dataField: 'kode_transaksi',
				text: 'Kode Transaksi',
				headerAlign: 'center'
			},
			{
				dataField: 'data_transaksi.data_pembeli.username',
				text: 'Pembeli',
				headerAlign: 'center',
				align: 'center'
			},
			{
				dataField: 'data_transaksi.data_pembayaran.no_rekening',
				text: 'Nomor Rekening Pembeli',
				headerAlign: 'center',
				align: 'center'
			},
			{
				dataField: 'total_bayar',
				text: 'Nominal',
				headerAlign: 'center',
				align: 'center',
				formatter: (cell, row) => {
					return 'Rp. ' + row.total_bayar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
				}
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				isDummyField: true,
				csvExport: false,
				formatter: (cell, row) => (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button
							color="success"
							size="sm"
							className="btn btn-icon"
							onClick={() => this.konfirmasiPengembalianDana(row.uuid)}
						>
							Kembalikan dana
						</Button>
					</div>
				)
			}
		];

		const columns2 = [
			{
				dataField: 'kode_transaksi',
				text: 'Kode Transaksi',
				headerAlign: 'center'
			},
			{
				dataField: 'data_transaksi.data_pembeli.username',
				text: 'Pembeli',
				headerAlign: 'center',
				align: 'center'
			},
			{
				dataField: 'data_transaksi.data_pembayaran.no_rekening',
				text: 'Nomor Rekening Pembeli',
				headerAlign: 'center',
				align: 'center'
			},
			{
				dataField: 'total_bayar',
				text: 'Nominal',
				headerAlign: 'center',
				align: 'center',
				formatter: (cell, row) => {
					return 'Rp. ' + row.total_bayar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
				}
			},
			{
				dataField: 'data_status_transaksi_baru.status',
				text: 'Status',
				headerAlign: 'center',
				align: 'center'
			}
		];

		return (
			<div>
				<Breadcrumb title="Data Pengembalian Dana" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-12">
											<Nav tabs className="tabs-color">
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '1'
														})}
														onClick={() => {
															this.toggle_icon('1');
														}}
													>
														<i className="icofont icofont-checked" />
														Proses Pengembalian Dana
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '2'
														})}
														onClick={() => {
															this.toggle_icon('2');
														}}
													>
														<i className="icofont icofont-check" />Daftar Pengembalian Dana
													</NavLink>
												</NavItem>
											</Nav>

											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: 15 }}>
														{/* <Row>
															<div className="col-sm-12">
																<form>
																	<label>
																		<input
																			name="name"
																			value={this.state.name}
																			onChange={(e) => this.handleChange(e)}
																			style={{ marginBottom: '0px' }}
																			className="form-control"
																			placeholder="Cari data..."
																		/>
																	</label>
																</form>
															</div>
														</Row> */}
														<Row>
															<Col sm="12">
																<BootstrapTable
																	keyField="uuid"
																	data={data}
																	columns={columns}
																/>
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
															</Col>
														</Row>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: 15 }}>
														<Row>
															<div className="col-sm-12 mt-2">
																{/* <form>
																	<label>
																		<input
																			name="name"
																			value={this.state.name}
																			onChange={(e) => this.handleChange(e)}
																			style={{ marginBottom: '0px' }}
																			className="form-control"
																			placeholder="Cari data..."
																		/>
																	</label>
																</form> */}
																{/* <form>
                                                                    <label>
                                                                        <input
                                                                           // name='name'
                                                                            // value={this.state.name}
                                                                            onChange={e => this.handleChange(e)}
                                                                           
                                                                            className="form-control"
                                                                            placeholder="Ketikkan sesuatu..."
                                                                            />
                                                                    </label>
                                                                    <button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary ml-2">Cari Data</button>         
                                                                </form> */}
															</div>
														</Row>
														<Row>
															<Col sm="12">
																<BootstrapTable
																	keyField="uuid"
																	data={data}
																	columns={columns2}
																/>
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
															</Col>
														</Row>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
			</div>
		);
	}
}

export default VerifikasiPengembalianDana;
