export const MENUITEMS = [
	{
		path: '/dashboard',
		icon: 'desktop',
		title: 'Dashboard',
		type: 'link'
	},
	// {
	// 	title: 'Data Master',
	// 	icon: 'blackboard',
	// 	type: 'sub',
	// 	active: false,
	// 	children: [
	// 		{ path: '/data/pengguna', title: 'Data Pengguna', type: 'link' },
	// 		{ path: '/fitur/kategori-usaha', title: 'Kategori Usaha', type: 'link' },
	// 		{ path: '/fitur/jenis-etalase', title: 'Jenis Etalase', type: 'link' },
	// 		{ path: '/fitur/jenis-pengiriman', title: 'Jenis Pengiriman', type: 'link' },
	// 		{ path: '/fitur/jenis-pembayaran', title: 'Jenis Pembayaran', type: 'link' },
	// 		{ path: '/data/bank', title: 'Data Bank', type: 'link' },
	// 		{ path: '/data/list-api', title: 'Konfigurasi Desa', type: 'link' }
	// 	]
	// },
	
	{
		path: '/toko',
		icon: 'shopping-cart',
		active: false,
		title: 'Toko / UMKM',
		type: 'link'
	},
	{
		path: '/verifikasi_transaksi',
		icon: 'shopping-cart-full',
		active: false,
		title: 'Transaksi',
		type: 'link'
	},
	{
		path: '/verifikasi_pencairan_dana',
		icon: 'receipt',
		active: false,
		title: 'Pencairan Dana',
		type: 'link'
	},
	{
		path: '/verifikasi_pengembalian_dana',
		icon: 'money',
		active: false,
		title: 'Pengembalian Dana',
		type: 'link'
	}
	// {
	// 	path: '/produk',
	// 	icon: 'support',
	// 	active: false,
	// 	title: 'Produk',
	// 	type: 'link'
	// },
	// {
	// 	path: '/promo',
	// 	icon: 'alert',
	// 	active: false,
	// 	title: 'Promo',
	// 	type: 'link'
	// },
	// {
	// 	path: '/produk-promo',
	// 	icon: 'alert',
	// 	active: false,
	// 	title: 'Produk Promo',
	// 	type: 'link'
	// }
];
