import axios from 'axios';

//dev Sungai Petai
// export const API_URL = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/bumdes/';
// export const API_URL2 = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/mobile/';

//demo
// export const API_URL = 'https://ws-demo-desa-marketplace.digidesa.com/api/v1/bumdes/'; 
// export const API_URL2 = 'https://ws-demo-desa-marketplace.digidesa.com/api/v1/mobile/'; 


//prod marketplace
const API_URL = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/bumdes/';
const API_URL2 = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/mobile/';

export function Get(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	console.log(table);
	if (id === null) {
		axios
			.get(`${API_URL + table}`, { headers: {Authorization: `Bearer ${token}`}})
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`,{ headers: {Authorization: `Bearer ${token}`}})
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function GetMobile(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	console.log(table);
	if (id === null) {
		axios
			.get(`${API_URL2 + table}`, { headers: {Authorization: `Bearer ${token}`} })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL2 + table}/${id}`, { headers: {Authorization: `Bearer ${token}`} })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Delete(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	axios
		.delete(`${API_URL + table}/${id}`, { headers: {Authorization: `Bearer ${token}`} })
		.then((response) => response.status)
		.then((status) => {
			cb(status);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {

	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data,  { headers: {Authorization: `Bearer ${token}`}, validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, { headers: {Authorization: `Bearer ${token}`}, validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	axios
		.put(`${API_URL + table}/${id}`, data, { headers: {Authorization: `Bearer ${token}`}, validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	axios
		.patch(`${API_URL + table}/${id}`, data,  { headers: {Authorization: `Bearer ${token}`}, validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {

	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((response) => {
			cb(response);
		});
}

export function PostStatus(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token'):'';
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data,  { headers: {Authorization: `Bearer ${token}`}, validateStatus: false })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data,  { headers: {Authorization: `Bearer ${token}`}, validateStatus: false })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	}
}
export default API_URL;
