import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
	uuid: '',
}

class DetailToko extends Component {

	constructor(props) {
		super(props);
		this.state = {
            data: [],
			data2: [],
			data3: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

           

			// data for select
			list_desa: [],
			id_desa: '',
			list_kategori_usaha: [],
			id_kategori_usaha: '',
			list_pengguna: [],
			id_pengguna: '',
			id_status_toko: null,

			//Tab Pane
			active_tab_icon: '1',
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
		  this.setState({
			active_tab_icon: tab
		  });
		}
	  }
	
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
        }

		let id = this.props.location.state.uuid;

		Get('produk-toko/list/'+id + '' + link, null, (lihat) => {
			console.log("GeT BY PROMO")
			console.log(lihat)
			console.log("GeT BY PROMO")
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
				
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		let addProduk = {
			q: e.target.value,
		};
		// let id = this.props.match.params.postID;
		Post('produk-toko/list', null, addProduk, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}
	
	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_toko.id_toko = '';
			this.state.id_kategori_usaha = '';
			this.state.id_desa = '';
			this.state.id_pengguna = '';
			this.state.data_toko.nama_toko = '';
			this.state.data_toko.telepon_toko = '';
			this.state.data_toko.alamat_toko = '';
			this.state.data_toko.lat_toko = '';
			this.state.data_toko.lang_toko = '';
			this.state.data_toko.jam_buka_toko = '';
			this.state.data_toko.jam_tutup_toko = '';
			this.state.id_status_toko = '';
			this.state.status.form = true;
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button>
			</>
		)
	}

	componentDidMount() {

        // ================== detail toko =================
		// console.log(this.props.match.params.postID)
		
		console.log("DATA DETAIL TOKO")
		console.log(this.props.location.state)
		console.log("DATA DETAIL TOKO")
		console.log("========UUIDNYA============")
		console.log(this.props.location.state.uuid)

        let id = this.props.location.state.uuid;

		//Tampil Data Jenis Pembayaran
		
		Get('jenis-pembayaran-toko/list', id, (lihat) => {
            console.log(lihat)
			if (lihat.result) {
				this.setState({ data2: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});

		//Tampil Data Jenis Pengiriman

		Get('jenis-pengiriman-toko/list', id, (lihat) => {
            console.log(lihat)
			if (lihat.result) {
				this.setState({ data3: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});


        // ============== end detail toko =================

		this.fetch({ page: null, where: [] });

	

	}

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: { value: e.value, label: e.label } });
					break;

				case 'id_desa':
					this.setState({ id_desa: { value: e.value, label: e.label } });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: { value: e.value, label: e.label } });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: '' });
					break;

				case 'id_desa':
					this.setState({ id_desa: '' });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: '' });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: '' });
					break;
			}
		}
	}
	
	render() {

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var data2 = this.state.data2;
		var data3 = this.state.data3;
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			
			{
				dataField: 'data_jenis_etalase.jenis_etalase',
				text: 'Jenis Etalase',
				sort: true
			},
			{
				dataField: 'nama_produk',
				text: 'Nama Produk',
				sort: true
            },
            
            {
				dataField: 'harga_produk' ,
				text: 'Harga Produk',
				sort: true,
				formatter: (cell, row) => {
					return 'Rp. ' + row.harga_produk.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
				}
            },
            {
				dataField: 'diskon',
				text: 'Diskon',
				sort: true
            },
            {
				dataField: 'stok',
				text: 'Stok',
				sort: true
            },
            
		];


		//Data table jenis pembayaran
		const columns2 = [
			{
				dataField: 'data_jenis_pembayaran_toko.kode_jenis_pembayaran',
				text: 'Kode Jenis Pembayaran',
				sort: true
			},
			{
				dataField: 'data_jenis_pembayaran_toko.jenis_pembayaran',
				text: 'Jenis Pembayaran',
				sort: true
            },
		];

		//Data table jenis pengiriman
		const columns3 = [
			{
				dataField: 'data_jenis_pengiriman.kode_jenis_pengiriman',
				text: 'Kode Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'data_jenis_pengiriman.jenis_pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
            },
		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Detail Toko" parent="Admin" />

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>


									<div>
										<Nav tabs className="tabs-color">
											<NavItem>
												<NavLink
												className={classnames({ active: this.state.active_tab_icon === '1' })}
												onClick={() => { this.toggle_icon('1'); }}
												><i className="icofont icofont-data"></i>
												Data Toko
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
												onClick={() => { this.toggle_icon('2'); }} >
												<i className="icofont icofont-pay"></i>Jenis Pembayaran
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
												onClick={() => { this.toggle_icon('3'); }} >
												<i className="icofont icofont-truck-alt"></i>Jenis Pengiriman
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '4' })}
												onClick={() => { this.toggle_icon('4'); }} >
												<i className="icofont icofont-listine-dots"></i>List Produk Toko
												</NavLink>
											</NavItem>
										</Nav>
										
										<TabContent activeTab={this.state.active_tab_icon}>
											
											<TabPane tabId="1">
											<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<div><br/><br/><h2 align="center">Data Toko</h2><hr/><br/><br/></div>
													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Toko</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Nama Toko" defaultValue={this.props.location.state.nama_toko} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">No. Telepon Toko</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Telepon Toko" defaultValue={this.props.location.state.telepon} disabled />
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat Toko</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Alamat Toko" defaultValue={this.props.location.state.alamat} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelurahan / Desa</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Kelurahan Desa" defaultValue={this.props.location.state.data_keldes.nama} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Latitude</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Latitude Toko" defaultValue={this.props.location.state.lat} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Langitude</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Langitude Toko" defaultValue={this.props.location.state.lang} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jam Buka</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Jam Buka Toko" defaultValue={this.props.location.state.jam_buka} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jam Tutup</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Jam Tutup Toko" defaultValue={this.props.location.state.jam_tutup} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Toko</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Jenis Toko" defaultValue={this.props.location.state.jenis_toko} disabled/>
														</Col>
													</FormGroup>

													<FormGroup className="row">
														<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kategori Usaha</Label>
														<Col sm='9'>
															<input className="form-control" id="nama_toko" type="text" placeholder="Kategori Usaha" defaultValue={this.props.location.state.data_kategori_usaha.kategori_usaha} disabled/>
														</Col>
													</FormGroup>
												</Col>
											</Row>

											</div>
										</TabPane>

											<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<div><br/><br/><h2 align="center">Jenis Pembayaran</h2><hr/><br/><br/></div>

														<BootstrapTable
															keyField="uuid"
															data={data2}
															columns={columns2}
														/>
														<div className="pull-right text-white">
															{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
													</div>
												</Col>
											</Row>

											</div>
										</TabPane>

											<TabPane tabId="3">
											<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<div><br/><br/><h2 align="center">Jenis Pengiriman</h2><hr/><br/><br/></div>

													<BootstrapTable
														keyField="uuid"
														data={data3}
														columns={columns3}
													/>
													<div className="pull-right text-white">
														{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
													</div>
												</Col>
											</Row>

											</div>
										</TabPane>

											<TabPane tabId="4">
											<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<div><br/><br/><h2 align="center">List Data Produk</h2><hr/><br/><br/></div>
													{/* <div className="row">
														<div className="col-sm-4">
															<input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} />
														</div>
													</div> */}
													<BootstrapTable
														keyField="uuid"
														data={data}
														columns={columns}
													/>
													<div className="pull-right text-white">
														{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
													</div>
												</Col>
											</Row>

											</div>
										</TabPane>

										</TabContent>

									</div>

                                    
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				
			</div>
		)
	}
}


export default DetailToko;
