import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import InfiniteScroll from "react-infinite-scroll-component";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';

import classnames from 'classnames';

// koneksi
import { Post, Get } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
	idnya: '',
	nama: '',
	uuid_bank: {
    value:'',label:''
  },
	cek: 'simpan',
}



class VerifikasiPencairanDana extends Component {

  constructor(props) {
    super(props);
    this.state = {  
      
        pagination: [],

        awal: "",
        sebelum: "",
        hal: [],
        setelah: "",
        akhir: "",

        dt: frmDef,
        
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        pesanAlert: '',

        //pencairan dana
        data_pencairan_diterima : [],
        data_pencairan_diproses : [],
        data : [],
        pencairanDanaId : [],
        pencairanDanaId2 : [],
        
        // Bank
        data_bank: [],
        uuid_bank: {
            value:'',
            label:''
        },
        nama_bank:'',
        kode_refrensi_bank:'',
        id_saldo_toko_detail:'',

        //Modal
        status: {
            form_verifikasi_dana: false,
            formDetail: false,
            btnForm: false,
            btnAksi: false
        },
        

        //Tab Pane
        active_tab_icon: '1',
        status2:'Diproses'
    
    };
  }

    // Bootstrap tabs function
	toggle_icon(tab) {

		if (this.state.active_tab_icon !== tab) {
            if(tab=='1'){
                this.state.status2='Diproses'
                this.state.active_tab_icon=tab
              this.forceUpdate();
                  this.fetch();
            }else{
                
                this.state.status2='Diterima'
                this.state.active_tab_icon=tab
              this.forceUpdate();
              
                this.fetch();

            }
		}
	}

    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
        show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
        alert: (
            <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
            You wrote: {value}
            </SweetAlert>
        )
        });
    }

    hideAlert = () => {
        this.setState({
        alert: null
        });
    }
    // =================================================

    fetch = (params = {}) => {
        let link = "?page=1";
        let where = [];
        let id_category = null;
        if (params.where) {
        where = params.where;
        }
        if (params.page) {
        switch (params.page) {
            case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
            case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
            case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
            case '<<': link = "?page=1"; break;
            default: link = "?page=" + params.page; break;
        }
        }

        Get('pencairan' + link+'&status='+this.state.status2, null, (lihat) => {
			if (lihat.result.data) {
                if(this.state.status2=="Diproses"){
                    this.setState({ data_pencairan_diproses: lihat.result.data, pagination: lihat.result });
                    this.pagination(lihat.result);
                } else{
                    this.setState({ data_pencairan_diterima: lihat.result.data, pagination: lihat.result });
                    this.pagination(lihat.result);
                }
			}
		});
        
    }

    // Pagination
    pagination = (data, type = null) => {
        // const data = this.state.pagination;
        // console.log(data);
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0) {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3) {
            start = 1;
            end = 5;
            if (data.last_page > data.current_page) {
            n = data.current_page + 1;
            }
        }
        else {
            p = data.current_page - 1;
            n = data.current_page + 1;
            start = data.current_page - 2;
            end = data.current_page + 2;
        }
        if (end >= data.last_page - 2) {
            p = data.current_page - 1;
            if (start >= 5) {
            start = data.last_page - 4;
            }
            end = data.last_page;
        }

        for (let i = start; i <= end; i++) {
            let warna = "success";
            if (i == data.current_page) {
            warna = "danger"
            }
            if (type == null) {
            hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            } else {
            hal.push(<a key={i} onClick={() => this.fetch({ page: i, category: type })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }

        }
        if (p > 0) {
            if (type == null) {
            sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            } else {
            sebelum = (<a onClick={() => this.fetch({ page: p, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }

        }
        if (n > 0) {
            if (type == null) {
            setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            } else {
            setelah = (<a onClick={() => this.fetch({ page: n, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }

        }

        if (type == null) {
            awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        } else {
            awal = (<a onClick={() => this.fetch({ page: 1, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a onClick={() => this.fetch({ page: data.last_page, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }

        }
        this.setState({ awal, sebelum, hal, setelah, akhir });
    }

    // Cari Data Table
    cariData = e => {
        if (e) {
        let addPencairan = {
            q: e.target.value,
        };
        Post('pencairan', null, addPencairan, (data) => {
            // console.log('data :', data)
          if (data.data.result) {
            this.setState({ data: data.data.result.data, pagination: data.data.result });
          }
        });
        } else {
        Get('pencairan', null, (lihat) => {
            // console.log('lihat :', lihat)
          if (lihat.result) {
            this.setState({ data: lihat.result.data, pagination: lihat.result });
            this.pagination(lihat.result);
          }
        });
        }
    }
  
    simpan = e => {
    e.preventDefault();
    this.state.status.form_verifikasi_dana = false;
    
    this.forceUpdate();

    let addStatus = {
        kode_refrensi_bank: document.getElementById("kode_refrensi_bank").value,
        status: 'Diterima',
        id_bank: this.state.id_bank,
    };

    let psn = 'Dibayar';
    let resstat = 200;

    Post('pencairan/verifikasi/'+this.state.id_saldo_toko_detail, null, addStatus, (res) => {
        // console.log(res);

        if (res.status === resstat) {
        this.setState({  show: true, basicType: 'success', basicTitle: 'Data Pencairan Dana', pesanAlert: 'Berhasil ' + psn });
        this.componentDidMount();
        }
        else {
        this.setState({  show: true, basicType: 'danger', basicTitle: 'Data Pencairan Dana', pesanAlert: 'Gagal'   });
        }
    });

        
    }


    bukaForm = (e) => {
        if (e == 'tambah') {
        this.state.data_toko.id_toko = '';
        this.state.id_kategori_usaha = '';
        this.state.id_desa = '';
        this.state.id_pengguna = '';
        this.state.data_toko.nama_toko = '';
        this.state.data_toko.telepon_toko = '';
        this.state.data_toko.alamat_toko = '';
        this.state.data_toko.lat_toko = '';
        this.state.data_toko.lang_toko = '';
        this.state.data_toko.jam_buka_toko = '';
        this.state.data_toko.jam_tutup_toko = '';
        this.state.id_status_toko = '';
        this.state.id_bank='';
        this.state.data.data_status_saldo_baru.id_saldo_toko_detail='';
        this.state.kode_refrensi_bank='';
        this.state.data_bank='';
        this.state.uuid_bank='';
        this.state.status.form = true;
        this.forceUpdate();
        } else if (e == 'edit') {
        this.state.status.form = true;
        this.forceUpdate();
        }

    }

    tutupForm = () => {
        this.state.status.form = false;
        this.state.status.formDetail = false
        this.state.status.form_verifikasi_dana = ''
        this.state.uuid_bank='';
        this.forceUpdate();
    }

    pilihTransaksiToko = (data) => {
        // console.log(data);
        this.state.status.form_verifikasi_dana= 'true';
        this.state.judul = "Verifikasi Pencairan Dana";
        this.state.id_saldo_toko_detail = data.uuid
        this.forceUpdate();

    }

    btnAksiVerifikasi = (cell, row) => {
        return (
        <>
            <button type="button" id={row.uuid} className="btn btn-success btn-sm" onClick={() => this.pilihTransaksiToko(row)}><i className="fa fa-check"></i></button>
        </>
        )
    }

    detailData =  (data) => {
        this.state.status.formDetail= true;
        this.state.judul = "Detail Pencairan Dana";
        this.forceUpdate();
        
        // console.log("=========================")
        // console.log("PARSING DATA DETAIL")
        // console.log("=========================")
        // console.log(data)

        if(data.data_status_saldo_baru.status=="Diproses"){
            var status = 'Menunggu Konfirmasi'
            var kode_refrensi_bank = '-'
            var tanggal_konfirmasi = '-'
        }
        else{
            var status = data.data_status_saldo_baru.status
            var kode_refrensi_bank = data.kode_refrensi_bank
            var tanggal_konfirmasi = data.data_status_saldo_baru.created_at
        }

        this.state.data.kode_refrensi_bank = kode_refrensi_bank;
        this.state.data.nominal = data.nominal;
        this.state.data.telepon = data.data_saldo.data_toko.telepon;
        this.state.data.alamat = data.data_saldo.data_toko.alamat;
        this.state.data.jam_buka = data.data_saldo.data_toko.jam_buka;
        this.state.data.jam_tutup = data.data_saldo.data_toko.jam_tutup;
        this.state.data.jenis_toko = data.data_saldo.data_toko.jenis_toko;
        this.state.data.nomor_rekening = data.data_saldo.data_toko.nomor_rekening;
        this.state.data.pemilik_toko = data.data_saldo.data_toko.data_pengguna.username;
        this.state.data.bank = data.data_saldo.data_toko.data_bank.nama_bank;
        this.state.data.status = status;
        this.state.data.tanggal_konfirmasi = tanggal_konfirmasi;

    }

    detailPencairanDana = (cell, row) => {
        return (
        <>
            <Button  color="primary" className="btn-icon" onClick={() => this.detailData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
        </>
        )
    }

    componentDidMount() {
       
        this.fetch({ page: null, where: [] });

       

        Get('bank', null, (data) => {
            // console.log("DATA BANK");
            // console.log(data);
            if (data.result) {
                let data_bank = [];
                data.result.map(dt => {
                    // console.log(dt);
                    data_bank.push({ value: dt.uuid, label: dt.nama_bank });
                });
                this.setState({ data_bank });
            }
        });

    }

    onChangeBank = (e) => {
            if (e) {
                this.setState({ uuid_bank: { value: e.value, label: e.label } });
            } else {
                this.setState({ uuid_bank: {} });
            }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault(); 
            let link = "?page=1";
           let search = {
                q: this.state.name,
            };
    
           
           Post('pencairan' + link+'&status='+this.state.status2, null, search, (data) => {

            //    console.log("key : ", search);
            //    console.log("data : ", data);
               
                if (data.data.result) {
                    // console.log('hasil : ', data.data.result);
                    if(this.state.status2=="Diproses"){
                        this.setState({ data_pencairan_diproses: data.data.result.data, pagination: data.data.result });
                        this.pagination(data.data.result);
                    } else{
                        this.setState({ data_pencairan_diterima: data.data.result.data, pagination: data.data.result });
                        this.pagination(data.data.result);
                    }
                    
                 
                }
            });
            
        }

  render() {
   
    var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    
    var dataPencairanDiterima = this.state.data_pencairan_diterima;
    var dataPencairanDiproses = this.state.data_pencairan_diproses;

    
    const { SearchBar } = Search;

    
   
    const columnPencairanDiterima = [
      
          {
            dataField: 'data_saldo.data_toko.nama_toko',
            text: 'Nama Toko',
            // sort: true
          },
          {
            dataField: 'nominal',
            text: 'Nominal',
            formatter: (cell, row) => {
                return 'Rp. ' + row.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            }
            // sort: true
          },
          {
            dataField: 'aksii',
            text: 'Detail',
            isDummyField: true,
            csvExport: false,
            //formatter: this.tombolAksiDetail,
            formatter: this.detailPencairanDana,
          },
          // {
          //   dataField: 'aksi',
          //   text: 'Aksi Batal verifikasi',
          //   isDummyField: true,
          //   csvExport: false,
          //   formatter: this.tombolAksi3,
          // },
    ];

    const columnPencairanDiproses = [
      
      {
        dataField: 'data_saldo.data_toko.nama_toko',
        text: 'Nama Toko',
        // sort: true
      },
      {
        dataField: 'nominal',
        text: 'Nominal',
        formatter: (cell, row) => {
            return 'Rp. ' + row.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
        // sort: true
      },

      {
        dataField: 'created_at',
        text: 'Tanggal Pengajuan',
        // sort: true
      },
     
      {
        dataField: 'aksii',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.detailPencairanDana,
      },
      {
        dataField: 'aksi',
        text: 'Aksi Verifikasi Pencairan Dana',
        isDummyField: true,
        csvExport: false,
        formatter: this.btnAksiVerifikasi,
        //formatter: this.tombolAksi3,
      },
    ];


    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = '';
      classname = 'btn btn-success';
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing { from} to { to} of { total} Result
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };

    return (
        
    <div>

        <Breadcrumb title="Data Pencairan Dana" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card mb-0">
                        <div className="card-body datatable-react">
                            <Row>
                                <div className="col-sm-12">
                                   
                                    <Nav tabs className="tabs-color">

                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.active_tab_icon === '1' })}
                                                onClick={() => { this.toggle_icon('1'); }}
                                            ><i className="icofont icofont-checked"></i>
                                            Proses Verifikasi
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
                                                onClick={() => { this.toggle_icon('2'); }} >
                                                <i className="icofont icofont-check"></i>Diterima
                                            </NavLink>
                                        </NavItem>
                       
                                    </Nav>
                     
                                    <TabContent activeTab={this.state.active_tab_icon}>
                                        <TabPane tabId="1">
                                            <div className="mt-15">
                                                <Row>
                                                    <Col sm={12}>
                                                        <div className="mt-5 mb-2">
                                                            <h2 align="center">Verifikasi Pencairan Dana</h2>
                                                            <hr/>
                                                        </div> 
                                                    
                                                        <form>
                                                            <label>
                                                                <input
                                                                    name='name'
                                                                    // value={this.state.name}
                                                                    onChange={e => this.handleChange(e)}
                                                                    
                                                                    className="form-control"
                                                                    placeholder="Ketikkan sesuatu..."
                                                                    />
                                                            </label>
                                                            <button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary ml-2">Cari Data</button>         
                                                        </form>
                                                    </Col>
                                                </Row>
                                                        
                                                <BootstrapTable
                                                    keyField="uuid"
                                                    data={dataPencairanDiproses}
                                                    columns={columnPencairanDiproses}
                                                    />
                                                
                                                    
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <div className="mt-15">
                                                <Row>
                                                    <Col sm={12}>
                                                        <div className="mt-5 mb-2">
                                                            <h2 align="center">Pencairan Dana Diterima</h2>
                                                            <hr/>

                                                            {/* <Col sm={6}> */}
                                                                <form>
                                                                    <label>
                                                                        <input
                                                                            name='name'
                                                                            // value={this.state.name}
                                                                            onChange={e => this.handleChange(e)}
                                                                           
                                                                            className="form-control"
                                                                            placeholder="Ketikkan sesuatu..."
                                                                            />
                                                                    </label>
                                                                    <button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary ml-2">Cari Data</button>         
                                                                </form>
                                                            {/* </Col>  */}
                                                        </div> 
                                                
                                                        <BootstrapTable
                                                            keyField="uuid"
                                                            data={dataPencairanDiterima}
                                                            columns={columnPencairanDiterima}
                                                            />

                                                                <div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
                                                
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>

                                    </TabContent>
                                   
                                </div>
                               
                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        
        {/* ======================================================== */}
        {/* ===================MODAL FORM DETAIL==================== */}
        {/* ======================================================== */}


        <Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
			
            <Form className="theme-form" onSubmit={this.simpan}>
                
                <div className="modal-header">
                    <h5 className="modal-title">{this.state.judul}</h5>
                </div>
				
                <div className="modal-body">
					<div className="row">
                        <div className="col-md-6">
                        
                            <div className="col-md-12">
                                <b>Pemilik Toko/Umkm</b>
                            </div>

                            <div className="col-md-12 mb-5" >
                                {this.state.data.pemilik_toko}
                            </div>

                            <div className="col-md-12">
                                <b>Alamat</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.alamat}
                            </div>

                            <div className="col-md-12">
                                <b>Nama Bank</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.bank}
                            </div>

                            <div className="col-md-12">
                                <b>No Rekening Toko</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.nomor_rekening}
                            </div>

                           
                        </div>

                        <div className="col-md-6">

                            <div className="col-md-12">
                                <b>Jenis Toko</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.jenis_toko}
                            </div>

                            <div className="col-md-12">
                                <b>Telepon</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.telepon}
                            </div>

                            <div className="col-md-12">
                                <b>Jam Buka Toko</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.jam_buka}
                            </div>

                            <div className="col-md-12">
								<b>Jam Tutup Toko</b>
                            </div>
                            <div className="col-md-12 mb-5">
                                {this.state.data.jam_tutup}
                            </div>

                            
                        </div>
                               
                    </div>

                    <>

                   
                    <div className="modal-header">
                            <h5 className="modal-title">Bukti Pencairan Dana</h5>
                    </div>  
                    
                    <div className="row">
                            <div className="col-md-6">
                            
                                <div className="col-md-12 mt-3">
                                    <b>Kode Refrensi Bank</b>
                                </div>
                                <div className="col-md-12 mb-5">
                                    {this.state.data.kode_refrensi_bank}
                                </div>

                                <div className="col-md-12">
                                    <b>Status</b>
                                </div>
                                <div className="col-md-12 mb-5">
                                    {this.state.data.status}
                                </div>

                                <div className="col-md-12">
                                    <b>Tanggal Konfirmasi</b>
                                </div>
                                <div className="col-md-12 mb-3">
                                    {this.state.data.tanggal_konfirmasi}
                                </div>
                            </div>

                        </div>

                    </>
				</div>
						
                <div className="modal-footer">
                    <button type="button" className="btn btn-warning"  onClick={this.tutupForm}>Tutup</button>
                </div>
			
            </Form>
		</Modal>




        {/* ======================================================== */}
        {/* ===================MODAL VERIFIKASI===================== */}
        {/* ======================================================== */}



        <Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form_verifikasi_dana} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
            <Form className="theme-form" onSubmit={this.simpan}>
                <div className="modal-header">
                    <h5 className="modal-title">{this.state.judul}</h5>
                </div>

                <div className="modal-body">
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label" >Nama Bank</Label>
                        <Col sm='9'>
                            <Select 
                                classNamePrefix="select" 
                                onChange={this.onChangeBank}
                                defaultValue={this.state.uuid_bank}
                                value={this.state.uuid_bank}
                                name="uuid_bank"
                                options={this.state.data_bank}
                                placeholder="Pilih Bank"
                                isClearable />
                                        
                        </Col>
                    </FormGroup>

                    <FormGroup className="row">
        
                        <Label className="col-sm-3 col-form-label">Kode Referensi Bank</Label>
                       
                        <Col sm='9'>
                            <input type="text" id="kode_refrensi_bank" className="form-control" defaultValue={this.state.kode_refrensi_bank}  />
                        </Col>
                    </FormGroup>

                </div>
                
                <div className="modal-footer">
                    <button type="button" className="btn btn-warning" onClick={this.tutupForm}>Tutup</button>
                    <button type="submit" className="btn btn-success"  onClick={this.simpan}>Simpan</button>
                </div>
            </Form>
        </Modal>

       
    </div>
    )
  }
}


export default VerifikasiPencairanDana;
