import React, { Component } from 'react';
import {Link} from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


const frmDef = {
	uuid: '',
	jenis_pembayaran: '',
}

class Toko extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			// data toko
			data_toko: {
				id_toko: '',
				nama_toko: '',
				telepon_toko: '',
				alamat_toko: '',
				lat_toko: '',
				lang_toko: '',
				jam_buka_toko: '',
				jam_tutup_toko: '',
				is_active: 0,
			},

		


			// data for select
			list_desa: [],
			id_desa: '',
			list_kategori_usaha: [],
			id_kategori_usaha: '',
			list_pengguna: [],
			id_pengguna: '',
			id_status_toko: null,

			name: '',
		};
	}

	
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Post('toko' + link, null, null, (lihat) => {
			
			if (lihat.data.result.data) {
				// console.log('hasil :', lihat.data.result.data)
				this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
				this.pagination(lihat.data.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		if (e) {
			let addToko = {
				q: e.target.value,
			};
			Post('toko', null, addToko, (data) => {
				if (data.data.result) {
					this.setState({ data: data.data.result.data, pagination: data.data.result });
				}
			});
		} else {
			Get('toko', null, (lihat) => {
				if (lihat.result) {
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		}
	}

	//tombol aksi pada table
	tombolAksi = (cell, row) => {
		return (
			<>
				<Link to={{ pathname: "/detail-toko/", state: row }}>
					<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button> 
				</Link> &nbsp;&nbsp;
				
			</>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


   onSubmit = (e) => {
	e.preventDefault();
	   
	   let addToko = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)
		
	   Post('toko', null, addToko, (lihat) => {
		//    console.log('data : ',lihat)
			if (lihat.data.result.data) {
				this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
			}
		});
       
	}


	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;

		const columns = [
		
			{
				dataField: 'nama_toko',
				text: 'Nama Toko',
				sort: true
			},
			{
				dataField: 'data_pengguna.username',
				text: 'Pemilik Toko',
				sort: true
			},
			{
				dataField: 'data_keldes.nama',
				text: 'Nama Desa',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Toko" parent="Admin" />

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
														/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary" style={{marginLeft:"10px"}}>Cari Data</button>         
											</form>
											{/* live search */}
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										</div>
										{/* <div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Toko", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div> */}
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				
			</div>
		)
	}
}


export default Toko;
