import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

// ** Import custom components **
import store from './store';
import App from './components/app';

// Dashboards
import Default from './components/dashboard/default/index_component';

// Pages
import Error404 from './components/fitur/error-404';
//import Login from './components/fitur/login';

// FEATURE
import Login from './components/feature/login/Login';
import Toko from './components/feature/toko/Toko';
import DetailToko from './components/feature/toko/DetailToko';
import Transaksi from './components/feature/transaksi/Transaksi';

import VerifikasiPencairanDana from './components/feature/pencairan/VerifikasiPencairanDana';



import DetailTransaksi from './components/data/detail-transaksi';
import VerifikasiTransaksi from './components/data/verifikasi_transaksi';
import VerifikasiPengembalianDana from './components/feature/pengembalian/VerifikasiPengembalianDana';

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('access_token') ? (
				<Redirect
					to={{
						pathname: '/dashboard',
						state: { from: props.location }
					}}
				/>
			) : (
				<Component {...props} /> // apa maksudnya ... props ?
			)}
	/>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('access_token') ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/',
						state: { from: props.location }
					}}
				/>
			)}
	/>
);

class Root extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter basename={'/'}>
					<ScrollContext>
						<Switch>
							<PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
							<Route path={`${process.env.PUBLIC_URL}/pages/error-404`} component={Error404} />

							<App>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/dashboard`} component={Default} />

								{/* Data Toko */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/toko`} component={Toko} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/detail-toko`} component={DetailToko} />
								{/* End Data Toko */}

								{/* Data Transaksi */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/transaksi`} component={Transaksi} />
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/detail-transaksi`}
									component={DetailTransaksi}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/verifikasi_transaksi`}
									component={VerifikasiTransaksi}
								/>
								{/* End Data Transaksi */}

								{/* pencairan dana */}
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/verifikasi_pencairan_dana`}
									component={VerifikasiPencairanDana} 
								/>
								{/* end pencairan dana */}
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/verifikasi_pengembalian_dana`}
									component={VerifikasiPengembalianDana}
								/>
							</App>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
